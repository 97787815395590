:root {
  --primary-color: #76be43;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.bounceIn {
  animation-duration: 0.7s;
}
.animated.bounceOut {
  animation-duration: 0.5s;
}
.animated.bounceIn {
  animation-name: bounceIn;
}
.animated.bounceOut {
  animation-name: bounceOut;
}
.animated.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.7s;
}
.animated.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.3s;
}
.animated.bounceInDown {
  animation-name: bounceInDown;
}
.animated.bounceOutUp {
  animation-name: bounceOutUp;
}
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: perspective(1px) scale3d(1, 1, 1);
  }
}
@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}
.rrt-confirm-holder .shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(50, 58, 68, 0.8);
}
.rrt-confirm-holder .rrt-confirm {
  width: 320px;
  background-color: white;
  position: absolute;
  z-index: 9;
  top: 20%;
  left: 50%;
  margin-left: -160px;
  box-shadow: 3px 3px 20px #333;
  border-radius: 4px;
  overflow: hidden;
}
.rrt-confirm-holder .rrt-confirm .rrt-message {
  width: 100%;
  padding: 5%;
  min-height: 50px;
  font-size: 1em;
  background-color: white;
  text-align: center;
  font-family: "open-sanscondensed-light", sans-serif;
  clear: both;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
  display: -ms-flexbox;
  display: flex;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 50px;
  text-transform: capitalize;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  float: left;
  border-top: 1px solid #f0f0f0;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
  background-color: #f5f5f5;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
  background-color: #60bb71;
  color: white;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
  background-color: #db6a64;
  color: white;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
  outline: none;
}
body.toastr-confirm-active {
  overflow: hidden;
}
.redux-toastr *,
.redux-toastr *:before,
.redux-toastr *:after {
  box-sizing: border-box;
}
.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
}
.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center {
  top: 0;
}
.redux-toastr .top-right,
.redux-toastr .bottom-right {
  right: 0;
}
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  bottom: 0;
}
.redux-toastr .top-left,
.redux-toastr .bottom-left {
  left: 0;
}
.redux-toastr .top-center,
.redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px;
}
@media (max-width: 320px) {
  .redux-toastr .top-left,
  .redux-toastr .top-right,
  .redux-toastr .top-center,
  .redux-toastr .bottom-left,
  .redux-toastr .bottom-right,
  .redux-toastr .bottom-center {
    width: 320px;
  }
  .redux-toastr .top-center,
  .redux-toastr .bottom-center {
    margin-left: -160px;
  }
}
.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: 0.94;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}
.redux-toastr .toastr:hover:not(.rrt-message) {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  opacity: 1;
}
.redux-toastr .toastr .toastr-status {
  width: 100%;
  height: 5px;
}
.redux-toastr .toastr .toastr-status.success {
  background-color: #60bb71;
}
.redux-toastr .toastr .toastr-status.warning {
  background-color: #f7a336;
}
.redux-toastr .toastr .toastr-status.info {
  background-color: #58abc3;
}
.redux-toastr .toastr .toastr-status.error {
  background-color: #db6a64;
}
.redux-toastr .toastr .rrt-left-container,
.redux-toastr .toastr .rrt-right-container {
  float: left;
  text-align: center;
  overflow: hidden;
}
.redux-toastr .toastr .rrt-left-container {
  width: 80px;
  top: 0;
  left: 0;
  position: absolute;
  bottom: 0;
}
.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  margin-top: -35px;
  left: 5px;
  line-height: 60px;
}
.redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: white;
  vertical-align: middle;
  margin-top: 5px;
}
.redux-toastr .toastr .rrt-middle-container {
  width: 65%;
  margin-left: 80px;
  position: relative;
  float: left;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
  font-size: 1em;
  text-align: left;
  padding: 10px 5px;
}
.redux-toastr .toastr .rrt-middle-container .rrt-title {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
}
.redux-toastr .toastr .rrt-right-container {
  width: 10%;
}
.redux-toastr .toastr .close-toastr {
  width: 10%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  font-size: 22px;
  border: none;
  outline: none;
  opacity: 0.5;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
}
.redux-toastr .toastr .close-toastr:hover {
  opacity: 1;
}
.redux-toastr .toastr .close-toastr:focus {
  outline: none;
}
.redux-toastr .toastr.rrt-info,
.redux-toastr .toastr.rrt-success,
.redux-toastr .toastr.rrt-warning,
.redux-toastr .toastr.rrt-error {
  color: white;
}
.redux-toastr .toastr.rrt-info {
  background-color: #58abc3;
}
.redux-toastr .toastr.rrt-info .rrt-progressbar {
  background-color: #378298;
}
.redux-toastr .toastr.rrt-success {
  background-color: #60bb71;
}
.redux-toastr .toastr.rrt-success .rrt-progressbar {
  background-color: #3e914d;
}
.redux-toastr .toastr.rrt-warning {
  background-color: #f7a336;
}
.redux-toastr .toastr.rrt-warning .rrt-progressbar {
  background-color: #d87e09;
}
.redux-toastr .toastr.rrt-error {
  background-color: #db6a64;
}
.redux-toastr .toastr.rrt-error .rrt-progressbar {
  background-color: #c5352e;
}
.redux-toastr .toastr.rrt-light .rrt-progressbar {
  background-color: #ccc;
}
.redux-toastr .toastr.rrt-light .toastr-icon {
  fill: #333 !important;
}
.redux-toastr .toastr.rrt-message {
  opacity: 1;
  border: 1px solid #dbdbdb;
}
.redux-toastr .toastr.rrt-message .rrt-title {
  width: 90%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-size: 1.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 50px;
  padding: 0 20px;
}
.redux-toastr .toastr.rrt-message .rrt-text {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  padding: 15px;
  font-size: 1.1em;
  margin-bottom: 20px;
}
.redux-toastr .toastr.rrt-message .rrt-text img {
  display: block;
  margin: 10px auto;
  max-width: 100%;
}
.redux-toastr .toastr.rrt-message .close-toastr {
  height: 50px;
}
.redux-toastr .toastr .rrt-progress-container {
  height: 5px;
  margin: 0 -20px -20px -60px;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
  border-radius: 0 0 0 4px;
  height: 100%;
}
.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.page-item {
  width: 45px;
  text-align: center;
  color: var(--primary-color) !important;
}
.page-item a {
  height: 38px;
}

.page-item.active .page-link {
  color: white !important;
}

.page-item .active {
  color: white !important;
}

.disabled > .fa {
  color: #dee2e6 !important;
}

.select-with-icon > .icon {
  position: absolute;
  top: 9px;
  left: 10px;
  color: #ccc;
  width: 10px;
}

.icon-pill {
  background-color: var(--primary-color);
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
}
.icon {
  text-align: center;
  cursor: pointer;
}

.Select-menu {
  position: fixed !important;
}
.Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  display: block;
}

.input-with-icon-right > .icon {
  position: absolute;
  top: 7px;
  right: 20px;
  color: #ccc;
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker__wrapper {
  width: 100%;
}

.react-date-picker__wrapper {
  border: 0;
}

.react-date-picker__calendar-button {
  display: none;
}
.rw-widget-input,
.rw-filter-input {
  box-shadow: none !important;
}
.scale {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  vertical-align: top;
  text-align: start;
}
.no-wrap {
  white-space: nowrap;
}

.error.rw-widget-container {
  border-color: #e64759 !important;
}
.gray-bg {
  background-color: #f3f3f4;
}
.login-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.pointer {
  cursor: pointer;
}

.vertical-timeline-element-content {
  padding: 1em !important;
}

.nowrap {
  white-space: nowrap;
}

.tippy-tooltip {
  background-color: var(--primary-color) !important;
}

.bg-blue {
  background-color: var(--primary-color) !important;
}

.fixed-top {
  left: initial !important;
  width: 100%;
  padding: 0.144rem 0.5rem;
  color: white !important;
  background: var(--primary-color);
}

.iconav-brand {
  background-color: var(--primary-color) !important;
  color: black;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link .active {
  color: black !important;
  background-color: white !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.iconav2 {
}
@media (min-width: 768px) {
  .iconav2 {
    position: fixed;
    /* top: 110px; */
    top: 111px;
    bottom: 0;
    left: 70px;
    width: 70px;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #eee;
    overflow-y: auto;
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.iconav2 .tooltip {
  white-space: nowrap;
}

.sticky-components {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
  height: calc(100vh - 7rem);
  overflow: hidden;
}

.wide-border {
  border: 2px dashed rgba(0, 0, 0, 0.125);
}

.icon-pointer {
  cursor: pointer;
}

.border-top-2 {
  border-top: 2px solid #dee2e6 !important  ;
  border: 0;
}

.rc-time-picker-input {
  font-size: 0.9rem !important;
  border: 0 !important;
  padding: 0 !important;
  height: auto !important;
}

.rc-time-picker-clear {
  line-height: 24px !important;
}

.center-childs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.table-layout-fixed {
  table-layout: fixed;
}

.horizontal-options {
  display: flex;
  justify-content: space-around;
}

.custom-signature {
  width: 100%;
  height: 45%;
  max-height: 45%;
}

.custom-signature-modal {
  min-width: 100%;
  width: 100%;
  max-height: 90%;
  height: 90%;
}

.modal-dialog.custom-signature-modal .modal-content .modal-body {
  height: 80vh;
  max-height: 80vh;
}

.modal-dialog.custom-signature-modal .modal-content .modal-body .bt-black {
  border-top: 0.1rem solid black;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}

.custom-signature-render {
  height: 2rem;
}

.color-white {
  color: white;
}

.color-red {
  color: #c5352e /*red;*/;
}

.color-pure-red {
  color: #ff1100 /*red;*/;
}

.floating-logo {
  float: right;
  width: 250px;
  padding: 1rem 1rem;
}

.table-hr {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

ul.normal-style {
  display: block;
  margin-bottom: 1em;
  padding-left: 40px;
}
ul.normal-style li {
  list-style-type: disc;
  margin-left: 0;
  margin-right: 0;
}

.grey-cell {
  background-color: #e8ecef;
}

.card-body .card-title {
  font-weight: bolder;
}

/* to fix the DatePicker inside a bordered table */
.table-bordered table.rw-calendar-grid tr.rw-calendar-row {
  height: 35px !important;
  width: 245px !important;
  border: none !important;
}
.table-bordered table.rw-calendar-grid td.rw-cell {
  height: 35px !important;
  border: none !important;
  padding-bottom: 3.6px !important;
  padding-left: 3.6px !important;
  padding-right: 3.6px !important;
  padding-top: 3.6px !important;
  vertical-align: middle !important;
  width: 35px !important;
}
.table-bordered table.rw-calendar-grid th.rw-head-cell {
  height: 28px !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  padding-bottom: 3.6px !important;
  padding-left: 3.6px !important;
  padding-right: 3.6px !important;
  padding-top: 3.6px !important;
  vertical-align: middle !important;
  width: 35px !important;
}

.rw-widget-picker > .rw-select {
  vertical-align: middle;
}
/*form styles*/
.steps {
  width: 675px;
  margin: 50px auto;
  position: relative;
}

.steps fieldset {
  background: white;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 17px 41px -21px rgb(0, 0, 0);
  padding: 20px 30px;
  border-top: 9px solid #1aa1c3;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;

  /*stacking fieldsets above each other*/
  position: absolute;
}
/*Hide all except first fieldset*/
.steps fieldset:not(:first-of-type) {
  display: none;
}
/*inputs*/
.steps label {
  color: #333333;
  text-align: left !important;
  font-size: 15px;
  font-weight: 200;
  padding-bottom: 7px;
  padding-top: 12px;
  display: inline-block;
}

.steps input,
.steps textarea {
  outline: none;
  display: block;
  width: 100%;
  margin: 0 0 20px;
  padding: 10px 15px;
  border: 1px solid #d9d9d9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #837e7e;
  font-family: "Roboto";
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  font-wieght: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.steps input:focus,
.steps textarea:focus {
  color: #333333;
  border: 1px solid #1aa1c3;
}

.error1 {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  position: absolute;
  left: 525px;
  margin-top: -58px;
  padding: 0 10px;
  height: 39px;
  display: block;
  color: #ffffff;
  background: #e62163;
  border: 0;
  font: 14px Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana,
    "Verdana Ref", sans-serif;
  line-height: 39px;
  white-space: nowrap;
}

.error1:before {
  width: 0;
  height: 0;
  left: -8px;
  top: 14px;
  content: "";
  position: absolute;
  border-top: 6px solid transparent;
  border-right: 8px solid #e62163;
  border-bottom: 6px solid transparent;
}

.error-log {
  margin: 5px 5px 5px 0;
  font-size: 19px;
  position: relative;
  bottom: -2px;
}

.question-log {
  margin: 5px 1px 5px 0;
  font-size: 15px;
  position: relative;
  bottom: -2px;
}

/*buttons*/
.steps .action-button,
.action-button {
  width: 100px !important;
  background: #1aa1c3;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px auto;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  display: block;
}

.steps .next,
.steps .submit {
  float: right;
}

.steps .previous {
  float: left;
}

.steps .action-button:hover,
.steps .action-button:focus,
.action-button:hover,
.action-button:focus {
  background: #9f2ad0;
}

.steps .explanation {
  display: block;
  clear: both;
  width: 540px;
  background: #f2f2f2;
  position: relative;
  margin-left: -30px;
  padding: 22px 0px;
  margin-bottom: -10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  top: 10px;
  text-align: center;
  color: #333333;
  font-size: 12px;
  font-weight: 200;
  cursor: pointer;
}

/*headings*/
.fs-title {
  text-transform: uppercase;
  margin: 0 0 5px;
  line-height: 1;
  color: #1aa1c3;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #837e7e;
  margin-bottom: 20px;
  text-align: center;
}
/*progressbar*/
#progressbar {
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  display: flex;
  width: 100%;
  text-align: center;
}
#progressbar li {
  list-style-type: none;
  color: rgb(51, 51, 51);
  text-transform: uppercase;
  font-size: 10px;
  width: 20%;
  float: left;
  position: relative;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  background: white;
  border-radius: 3px;
  margin: 0 auto 5px auto;
}
/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}
#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #1aa1c3;
  color: white;
}

/* RESPONSIVE */

/* moves error logs in tablet/smaller screens */

@media (max-width: 1000px) {
  /*brings inputs down in size */
  .steps input,
  .steps textarea {
    outline: none;
    display: block;
    width: 100% !important;
  }

  /*brings errors in */
  .error1 {
    left: 345px;
    margin-top: -58px;
  }
}

@media (max-width: 675px) {
  /*mobile phone: uncollapse all fields: remove progress bar*/

  .steps {
    width: 100%;
    margin: 50px auto;
    position: relative;
  }

  #progressbar {
    display: none;
  }

  /*move error logs */
  .error1 {
    position: relative;
    left: 0 !important;
    margin-top: 24px;
    top: -11px;
  }

  .error1:before {
    width: 0;
    height: 0;
    left: 14px;
    top: -14px;
    content: "";
    position: absolute;
    border-left: 6px solid transparent;
    border-bottom: 8px solid #e62163;
    border-right: 6px solid transparent;
  }

  /*show hidden fieldsets */
  .steps fieldset:not(:first-of-type) {
    display: block;
  }

  .steps fieldset {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 45px;
  }

  .steps .next,
  .steps .previous {
    display: none;
  }

  .steps .explanation {
    display: none;
  }

  .steps .submit {
    float: right;
    margin: 28px auto 10px;
    width: 100% !important;
  }
}

/* Info */
.info {
  width: 300px;
  margin: 35px auto;
  text-align: center;
  font-family: "roboto", sans-serif;
}

.info h1 {
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 5px;
}
.info span {
  color: #666666;
  font-size: 13px;
  margin-top: 20px;
}
.info span a {
  color: #666666;
  text-decoration: none;
}
.info span .fa {
  color: rgb(226, 168, 16);
  font-size: 19px;
  position: relative;
  left: -2px;
}

.info span .spoilers {
  color: #999999;
  margin-top: 5px;
  font-size: 10px;
}

.grey {
  background-color: rgba(0, 0, 0, 0.125);
}

.card-body {
  z-index: 0;
}

.wide-border {
  border: 2px dashed rgba(0, 0, 0, 0.125);
}

.width-100 {
  width: 100%;
}
.zIndex-0 {
  z-index: 0;
}
.zIndex-1 {
  z-index: 1;
}
.zIndex-2 {
  z-index: 2;
}
.zIndex-3 {
  z-index: 3;
}
.zIndex-4 {
  z-index: 4;
}
.zIndex-5 {
  z-index: 5;
}
.zIndex-6 {
  z-index: 6;
}
.zIndex-7 {
  z-index: 7;
}
.zIndex-8 {
  z-index: 8;
}
.zIndex-9 {
  z-index: 9;
}
.zIndex-10 {
  z-index: 10;
}

.zIndex-1030 {
  z-index: 1030;
}

.zIndex-1050 {
  z-index: 1050;
}

.pricing {
  display: flex;
}

li .dots {
  border-bottom: 1px dotted black;
  flex: 1;
}

.mt-22 {
  margin-top: 22px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-30px {
  margin-top: 30px;
}

.line-height-1-3 {
  line-height: 1.3rem;
}

.mt-175 {
  margin-top: 1.75rem;
}
.mt-125 {
  margin-top: 1.25rem;
}
.vertical-align-middle {
  vertical-align: middle !important;
}

.rw-multiselect-tag {
  margin-top: 0 !important;
  margin-bottom: 0.35rem !important;
}

.rw-multiselect-taglist > li:first-child {
  margin-left: 0.35rem;
}
.button-field-equal {
  margin-top: 1.33rem;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.sortableHelper {
  z-index: 1051 !important;
}

.rw-popup-container {
  position: absolute;
  top: 100%;
  left: -6px;
  right: -6px;
  z-index: 1052 !important;
}

.fileuploader {
  position: relative;
  background: white;
  width: 100%;
  height: 200px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.fileuploader #upload-label {
  background: #2196f3;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 16px;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  min-width: 20%;
  text-align: center;
  padding-top: 40px;
  transition: 0.8s all;
  -webkit-transition: 0.8s all;
  -moz-transition: 0.8s all;
  cursor: pointer;
}
.fileuploader.active {
  background: #2196f3;
}
.fileuploader.active #upload-label {
  background: #fff;
  color: #2196f3;
}
.fileuploader #upload-label span.title {
  font-size: 1.1em;
  font-weight: bold;
  display: block;
}

.fileuploader .active {
  background: #2196f3;
}

.fileuploader #upload-label i {
  text-align: center;
  display: block;
  background: white;
  color: #2196f3;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  width: 80px;
  height: 80px;
  font-size: 60px;
  padding-top: 10px;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}
/** Preview of collections of uploaded documents **/
.preview-container {
  position: fixed;
  right: 10px;
  bottom: 0px;
  width: 300px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  visibility: hidden;
}
.preview-container #previews {
  max-height: 400px;
  overflow: auto;
}
.preview-container #previews .zdrop-info {
  width: 88%;
  margin-right: 2%;
}
.preview-container #previews.collection {
  margin: 0;
}
.preview-container #previews.collection .actions a {
  width: 1.5em;
  height: 1.5em;
  line-height: 1;
}
.preview-container #previews.collection .actions a i {
  font-size: 1em;
  line-height: 1.6;
}
.preview-container #previews.collection .dz-error-message {
  font-size: 0.8em;
  margin-top: -12px;
  color: #f44336;
}
.preview-container .header {
  background: #2196f3;
  color: #fff;
  padding: 8px;
}
.preview-container .header i {
  float: right;
  cursor: pointer;
}

.download-icon {
  border-radius: 100%;
  width: 60px;
  font-size: 40px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: block;
  background: white;
  color: #2196f3;
}

.filecontainer {
  background: white;
  width: 100%;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.delete-file-button {
  position: absolute;
  top: 0;
  right: 20px;
}

.text-info {
  color: #1aa1c3 !important;
}

.nowrap {
  white-space: nowrap;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
tr.active {
  color: #fff;
  background-color: #309ab4;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1200px;
  }
}

.detail-application-checkbox .form-check {
  margin-bottom: 0.2rem !important;
}

.form-check-label {
  display: inline-block !important;
}

input:disabled,
button:disabled,
button:disabled span {
  cursor: not-allowed;
}

.grayBackground {
  background-color: #aeb0b5;
}
